const labelStyle = {
    fontSize: 18,
    cssStyle: 'fontFamily:Oswald;',
}

class EdliyGraph {
    constructor(name, style) {
        const defaultStyle = {
            keepaspectratio: true,
            axis:false,
            ticks:true,
            grid:true,
            showCopyright:false,
            showNavigation:false,
            pan: {enabled:false},
        }

        let mergedStyle = defaultStyle;
        for (key in style) {
            mergedStyle[key] = style[key];
        }

        this.board = JXG.JSXGraph.initBoard(name, mergedStyle);
        this.turtle = undefined;
    }

    point(coords, ...args) {
    }

    turtle(startCoords) {
        this.turtle = this.board.create('turtle',
                                        startCoords,
                                        {
                                            strokeOpacity: 1,
                                        });
    }
}

class Parser {
    constructor(text) {
        this.text = text;
    }

    parse() {
        const KnownTokens = {
            COMMENT: '#',
            FOR: 'for',
            ITERATOR: 'iterator',
        }

        // Expect something like:
        // for (<Symbol> = <Lower>; <Symbol> < <Upper>; <Symbol>++) {
        //     <Function>(<Symbol>);
        //     
        // }

        let index = 0;
        var expect = function(fixedText, at) {
            if (at >= this.text.length ||
                (at + fixedText.length) >= this.text.length) {
                return false;
            }

            return substring(this.text, at, fixedText.length) == fixedText;
        }

        var advance = function(fixedText) {
            index += fixedText.length;
        }


    }

    is_valid() {
        return false;
    }
}

class ForLoopExample {
    // constructor(turtle) {
        // this.turtle = turtle;
    constructor() {
        //this.resetPosition = [turtle.X(), turtle.Y()];
        this.i = 0;
        this.reset();
    }

    next() {
        this.singleStep();
    }

    previous() {
        const currentStep = this.step;
        if (currentStep > 0) {
            this.warpToStep(currentStep - 1);
        }
    }

    singleStep() {
        switch (this.step) {
            case 0:
                this.setPC(1);
                break;
            case 1:
                this.setPC(4);
                break;
            case 2:
                // this.turtle.forward(1);
                this.setPC(2);
                break;
            case 3:
                this.i = 1;
                this.setPC(1);
                break;
            case 4:
                this.setPC(4);
                break;
            case 5:
                // this.turtle.forward(1);
                this.setPC(2);
                break;
            case 6:
                this.i = 2;
                this.setPC(1);
                break;
            case 7:
                this.setPC(4);
                break;
            case 8:
                // this.turtle.forward(1);
                this.setPC(2);
                break;
            case 9:
                this.i = 3;
                this.setPC(1);
                break;
            case 10:
                this.setPC(5);
                break;
            case 11:
                this.setPC(6);
                break;
/*
            case 0:
                this.turtle.forward(1);
                this.setPC(1);
                break;
            case 1:
                this.turtle.forward(1);
                this.setPC(1);
                break;
            case 2:
                this.turtle.forward(1);
                this.setPC(3);
                break;
            case 3:
                this.turtle.right(90);
                this.setPC(4);
                break;
            case 4:
                this.turtle.setPenColor('blue');
                this.setPC(5);
                break;
            case 5:
                this.turtle.forward(1);
                this.setPC(6);
                break;
            case 6:
                this.turtle.forward(1);
                this.setPC(6);
                break;
            case 7:
                this.turtle.forward(1);
                this.setPC(6);
                break;
            case 8:
                this.turtle.forward(1);
                this.setPC(7);
                break;
*/
            default:
                return;
        }
        this.step++;
    }

    warpToStep(step) {
        this.reset();
        while (this.step < step) {
            this.singleStep();
        }
    }

    reset() {
        // this.turtle.clearScreen();
        // this.turtle.setPenColor('red');
        // this.turtle.setPenSize(9);
        this.step = 0;
        this.i = 0;
        this.setPC(0);
    }

    setPC(sourceLine) {
        const sources = [
            "for (i = 0;",
            "     i < 3;",
            "     i++)",
            "{",
            "  forward(1);",
            "}",
            "// end",
            // "turnClockwise(90);",
            // "color('blue');",
            // "for (i = 0; i < 4; ++i) {",
            // "  forward(1);",
            // "}",
        ];

        let sourceDisplay = document.getElementById('source-display');
        let variableDisplay = document.getElementById('variables');
        let flowchartDisplay = document.getElementById('flowchart');
        let modifiedSources = sources;
        for (let i = 0; i < sources.length; ++i) {
            const isVisible = (i == sourceLine);
            modifiedSources[i] = "<img style='height: 1.5em; vertical-align:middle; " + (!isVisible?(" visibility:hidden"):"") + "' src='/assets/pointing.svg' />" + modifiedSources[i];
        }
/*
        if (0 <= sourceLine && sourceLine < sources.length) {
            modifiedSources[sourceLine] = "<img style='height: 1em' src='assets/pixel-perfect-right-arrow-pc.svg' />" + modifiedSources[sourceLine];
        }
*/
        sourceDisplay.innerHTML = "<span style='white-space: pre; font-family: monospace;'>"+modifiedSources.join('\n')+"</span>";

        let image = undefined;
        if (sourceLine == 0) {
            image = "flowchart-output/begin.svg";
        } else if (sourceLine == 1) {
            image = "flowchart-output/test.svg";
        } else if (sourceLine == 2) {
            image = "flowchart-output/increment.svg";
        } else if (sourceLine == 4) {
            image = "flowchart-output/loop-body.svg";
        } else if (sourceLine == 6) {
            image = "flowchart-output/end.svg";
        }

        if (image) {
            flowchartDisplay.innerHTML = "<img src = \"assets/" + image + "\" />";
        }

        const emitCode = function (text, color = undefined) {
            let colorTag = "";

            if (color) {
                colorTag = "color: " + color + ";";
            }

            return "<span style='" + colorTag +
                   "white-space: pre; font-family: monospace;'>" + text +
                   "</span>";
        }

        const emitNormalText = function (text) {
            return "<span>" + text + "</span>";
        }

        const newLine = "<br>";

        variableDisplay.innerHTML = emitCode("i") +
                                    emitNormalText(" is now ") +
                                    emitCode(String(this.i));
        if (sourceLine == 1) {
            variableDisplay.innerHTML += newLine +
                                         emitNormalText("Is ") +
                                         emitCode("i < 3") +
                                         emitNormalText("? ")
            if (this.i < 3) {
                variableDisplay.innerHTML += emitCode("True", "green") +
                                             newLine +
                                             emitNormalText("Loop body will run.");
            } else {
                variableDisplay.innerHTML += emitCode("False", "red") +
                                             newLine +
                                             emitNormalText("Loop will end.");
            }
        }
        if (sourceLine == 6) {
            variableDisplay.innerHTML += newLine +
                                         emitNormalText("Loop has finished.");
        }
    }
}

const ForLoopExampleFactory = {
    run: function() {
        let f = new ForLoopExample();
        return f;
    }
}

class FlowChart {
    constructor(nodes, edges, marker) {
        this.nodes = nodes;
        this.edges = edges;
        this.marker = marker;
        this.reset();
    }

    update() {
     
    }

    reset() {
    }
}

const Boxes = {
    box1: function () {
      JXG.Options.board.minimizeReflow = 'none';
      JXG.Options.layer['line'] =8;
      JXG.Options.layer['point'] =14;
      var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-6, 4, 5, -5],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}});
      brd2.suspendUpdate();
//congruent triangle//
      var t = brd2.create('turtle',[-6, -1], {strokeOpacity:1});
      let example = new ForLoopExample(brd2.create('turtle',
                                                   [-3, 0], 
                                                   {strokeOpacity:1}));
      t.setPenSize(9);
      //t.setPos(0,3);
      t.setPenColor('purple');
      t.right(90);
      var alpha=0;
      //var beta =0;
      //var gamma=0;
      var a =brd2.create('point', [-6, -1],{name:'Head', size:5, fillColor:'white', strokeColor:'black', fixed:true, label:{fontSize:18, offset:[-50, 0], cssStyle:'fontFamily:Oswald;'}});
      var b =brd2.create('point', [0.6,-1.25],{fixed:true, name:'Tail', size:5, fillColor:'white', strokeColor:'black', label:{fontSize:18, cssStyle:'fontFamily:Oswald;'},visible:function(){if(alpha < 94){return false} else {return true}}});
      //var tf = brd2.create('transform', [function(){return a.Y()-3+1}, function(){return a.Y()-3+1}], {type: 'scale'});
      var run = function() {
        if (alpha < 5) {  // stop after two rounds
          t.forward(.6);
          alpha += 1;
          t.setPenColor('purple');
        }
        if ( alpha>=5 & alpha < 10) {  // stop after two rounds
          t.forward(0.2);
          t.left(8);
          alpha += 1;
          t.setPenColor('purple');
        }
        if ( alpha>=10 & alpha < 32) {  // stop after two rounds
          t.forward(0.15);
          t.right(12);
          alpha += 1;
          t.setPenColor('orange');
        }
        if ( alpha>=32 & alpha < 50) {  // stop after two rounds
          t.forward(0.1)
          alpha += 1;
          t.setPenColor('red');
        }
        if ( alpha>=50 & alpha < 61) {  // stop after two rounds
          t.forward(0.145);
          t.left(12);
          alpha += 1;
          t.setPenColor('green');
        }
        if ( alpha==61) {
          t.forward(0.09);
          t.left(0);
          alpha += 1;
          t.setPenColor('purple');
        }
        if ( alpha>=62 & alpha < 71) {  // stop after two rounds
          t.forward(0.15);
          t.left(12.4);
          alpha += 1;
          t.setPenColor('green');
        }
        if ( alpha>=71 & alpha < 85) {  // stop after two rounds
          t.forward(0.198);
          t.right(1.4)
          alpha += 1;
          t.setPenColor('blue');
        }
        if (alpha ==85) {  // stop after two rounds
          t.forward(0.08);
          t.right(0)
          alpha += 1;
          t.setPenColor('orange');
        }
        if (alpha>=86 & alpha < 90) {  // stop after two rounds
          t.forward(0.15);
          alpha += 1;
          t.pd();
          t.setPenColor('blue');
        }
        if (alpha>=90 & alpha < 95) {  // stop after two rounds
          t.forward(0.15);
          alpha += 1;
          t.setPenColor('blue');
        }
		if(alpha <95){
        setTimeout(run, 260);
		}
      }
      // let zz = brd2.create('input', [-1, -1, 's', 'label']);

    let magicGraph = document.querySelector("#jxgbox1");
    let inputFields = magicGraph.querySelectorAll("input");

    var onEachPress = function(event) { res(); }

    inputFields.forEach(function(field) {
      field.addEventListener('change', onEachPress);
    });

      var hat =brd2.create('point', [-6.75, 4.], {visible:false});
      var sailor = brd2.create('image',['/assets/sailor.svg', [-7.25, 3.], [1.5, 1.5]], {fixed:true});
      var spe = brd2.create('image',['/assets/speaker.svg', [-6.8, 1.7], [0.75, 0.75]], {fixed:true, visible:function(){if(alpha > 0 && alpha < 17 || alpha > 20 && alpha < 40 || alpha >=45 && alpha < 60 || alpha >=64 && alpha <= 80 || alpha >80 && alpha<90 || alpha >92){return true } else {return false}}});
      //var rope = brd2.create('image',['assets/fiber.svg', [-4.25, 3.], [0.5, 0.5]], {attractors:[hat],attractorDistance:1,  snatchDistance:2.});
      //var sail = function(){if(rope.X()-hat.X()<=0.0001 && rope.Y()-hat.Y()<=0.0001){return run();}};
      sailor.on('down', function(){t.clearScreen();t.setPos(-6, -1); t.right(90);alpha=0; t.setPenColor('purple'); t.init(-6,-1, 0);run();});
      // brd2.create('text', [-5.6, 3.75, 'Tap on sailor Hank to start !'],{cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(18*brd2.canvasWidth/500.)}, parse:true,fixed:true});
      //rope.on('down', function(){ct();});
      //sailor.on('up', function(){ct();});
      //sailor.on('up', function(){ct();});
      //brd2.create('text', [-7, 2.5, function(){return sail()}]);

      let prevArrow = brd2.create('image', ['/assets/pixel-perfect-left-arrow.svg',  [-5, -3], [1.0, 1.0]], {fixed:true, highlightFillOpacity:0.1});
      // prevArrow.on('down', function(){});
      prevArrow.on('up', function() { example.previous() });
      let nextArrow = brd2.create('image', ['/assets/pixel-perfect-right-arrow.svg', [-3, -3], [1.0, 1.0]], {fixed:true, highlightFillColor:'#0000BB'});
      nextArrow.on('up', function() { example.next() });

      brd2.unsuspendUpdate();
	},
}

// export default Boxes;
export default ForLoopExampleFactory;
