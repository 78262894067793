<template>
  <div>
    <h3 ref="ia">
      For Loop
    </h3>
    <p>
      The <b>for</b> loop is a common construct in programming. It is used to
      repeat an action a certain number of times.
    </p>
    <p>
      In this MagicGraph, we will use a <b>for</b> loop to draw the path of a
      turtle across a grid.
    </p>
    <p>
      The syntax of the <b>for</b> loop varies based on the language. Examples of
      for loops from Python, C and others are shown below. The examples print out
      the numbers 0-9 (inclusive).
    </p>
    <p>
      Python3:
      <pre>
for i in range(0, 10):
    print(i)
      </pre>
    </p>
    <p>
      C:
      <pre>
for (int i = 0; i < 10; ++i) {
    printf("%d\n", i);
}
      </pre>
    </p>
    <v-responsive>
      <v-layout
        justify-center
        align-center
        row
        wrap
      >
        <v-flex
          xs6
          sm6
          md6
          lg6
          xl6
        >
          <v-btn
            large
            depressed
            @click="previous"
          >
            <v-img
              src="/assets/undo.svg"
              aspect-ratio="1"
              contain
            />
          </v-btn>
          <v-btn
            large
            depressed
            @click="next"
          >
            <v-img
              src="/assets/redo.svg"
              aspect-ratio="1"
              contain
            />
          </v-btn>
          <h5>Source Code</h5>
          <div
            id="source-display"
          />
          <br>
          <h5>Code State Monitor</h5>
          <div
            id="variables"
          />
          <br>
        </v-flex>
        <v-flex
          xs6
          sm6
          md6
          lg6
          xl6
          justify-center
        >
          <div
            id="flowchart"
            style="justify-center"
          />
        </v-flex>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
// import Boxes from './Boxes.js'
import ForLoopExampleFactory from './Boxes.js'
export default {
  name: 'ForLoop',
  data() {
    return {
      ForLoopExample: undefined,
    }
  },
  created: function () {
    // Store mutations
    let title = 'For Loop';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'For Loop', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'MagicGraph: Programming a For Loop', img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    // Boxes.box1();
    this.ForLoopExample = ForLoopExampleFactory.run();
  },
  methods: {
    next: function () {
      this.ForLoopExample.next();
    },
    previous: function () {
      this.ForLoopExample.previous();
    },
  },
  metaInfo() {
  return{ title: 'For Loop',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn the basic structure of a For Loop.'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
